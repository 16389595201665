import React, { FC } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import BaseButtonInner from 'atoms/BaseButtonInner';

import { CaseStudyPageTransitionDuration } from 'constants/values';

type ButtonProps = {
  label: string | null;
  url?: string | null;
  direction?: 'left' | 'right' | 'none';
  isInternal?: boolean;
  isScrewIncluded?: boolean;
  hover?: boolean;
  handleOnClick?: () => any;
};

const BaseButton: FC<ButtonProps> = ({
  label,
  url,
  direction,
  isInternal,
  isScrewIncluded,
  hover,
  handleOnClick,
}) => {
  const router = useRouter();
  if (!url) return null;
  return (
    <>
      {isInternal ? (
        <Link href={url} passHref>
          <a
            onClick={(e) => {
              if (!handleOnClick) {
                return;
              }
              e.preventDefault();
              handleOnClick();
              setTimeout(() => {
                router.push(url);
              }, CaseStudyPageTransitionDuration);
            }}
          >
            <BaseButtonInner
              direction={direction}
              label={label}
              hover={hover}
              isScrewIncluded={isScrewIncluded}
            />
          </a>
        </Link>
      ) : (
        <a href={url} target="_blank" rel="noreferrer">
          <BaseButtonInner direction={direction} label={label} hover={hover} />
        </a>
      )}
    </>
  );
};

export default BaseButton;
