//This breakpoints should match tailwind.config breakpoint value.
export enum Breakpoints {
  TABLET = 640,
  LAPTOP = 1024,
  DESKTOP = 1280,
  LARGER_DESKTOP = 1536,
  X_LARGER_DESKTOP = 2160,
}

// duration for the transtion from Case Study page to Landing Tab.
export const LandingTabTransitionDuration = 200; //ms

// duration for the transtion from Landing Tab to Case Study and between Case Study pages.
export const CaseStudyPageTransitionDuration = 200; //ms

// Number of skeleton grid items of Case Study Grid Block in Landing Tab page.
export const CaseStudyTileCount = 2;

// Route paths referenced in the code
// Update if the slug is changed in Sanity
export enum RouteBasePaths {
  HYDRO = 'manhattan-hydraulics',
  WORDS = 'words',
  WORK = 'work',
  CASE_STUDY = 'case-study',
}

export const REVALIDATION_TIME = 600; // seconds (10 minutes)

export const ClosedTabHeight = 64; // px
