import React, { FC } from 'react';

type Props = {
  className?: string;
};

const Screw: FC<Props> = ({ className }) => {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M16 6.99999L12 13.9282L4 13.9282L-3.49691e-07 6.99999L4 0.0717945L12 0.0717948L16 6.99999Z"
        fill="#181818"
      />
    </svg>
  );
};

export default Screw;
