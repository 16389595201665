import { FC } from 'react';
import cx from 'classnames';

export type CutoutCirclesProps = {
  backgroundColor?: string;
};

const CutoutCircles: FC<CutoutCirclesProps> = ({ backgroundColor }) => {
  const SharedStyles =
    'bg-sand rounded-full w-3 laptop:w-5 h-3 laptop:h-5 absolute';
  const TopStyle = 'top-3 laptop:top-5';
  const BottomStyle = 'bottom-3 laptop:bottom-5';
  const LeftStyle = 'left-3 laptop:left-5';
  const RightStyle = 'right-3 laptop:right-5';

  return (
    <>
      {/* Top left */}
      <div
        className={cx(SharedStyles, TopStyle, LeftStyle)}
        style={{ backgroundColor: backgroundColor }}
      ></div>
      {/* Bottom left */}
      <div
        className={cx(SharedStyles, BottomStyle, LeftStyle)}
        style={{ backgroundColor: backgroundColor }}
      ></div>
      {/* Top right */}
      <div
        className={cx(SharedStyles, TopStyle, RightStyle)}
        style={{ backgroundColor: backgroundColor }}
      ></div>
      {/* Bottom right */}
      <div
        className={cx(SharedStyles, BottomStyle, RightStyle)}
        style={{ backgroundColor: backgroundColor }}
      ></div>
    </>
  );
};

export default CutoutCircles;
