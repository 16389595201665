import { useEffect } from 'react';
import debounce from 'utils/debounce';
import * as Ui from 'contexts/ui';

const useWindowSize = () => {
  const { setClientHeight, clientHeight, setClientWidth, clientWidth } =
    Ui.useContext();

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    setClientHeight(window.innerHeight);
    setClientWidth(window.innerWidth);

    const handleResize = () => {
      setClientHeight(window.innerHeight);
      setClientWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { width: clientWidth, height: clientHeight };
};

export default useWindowSize;
