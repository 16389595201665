import React, { FC } from 'react';
import { InferGetStaticPropsType } from 'next';

import { SanityClient } from 'lib/sanity';

import { Settings, LandingMenuSnippetPages } from 'lib/sanity/types';

import ErrorPage from 'components/ErrorPage';
import Meta from 'components/Meta';
import { REVALIDATION_TIME } from 'constants/values';

export type ErrorPageProps = {
  settings: Settings;
  landingMenuSnippetPages: LandingMenuSnippetPages;
};

const Custom404: FC<ErrorPageProps> = ({
  settings,
  landingMenuSnippetPages,
}) => {
  const { metaCard } = settings;
  return (
    <>
      <Meta seo={metaCard} />
      <ErrorPage
        settings={settings.errorPageSettings}
        landingMenuSnippetPages={landingMenuSnippetPages}
      />
    </>
  );
};

export const getStaticProps = async () => {
  const [settings, landingMenuSnippetPages] = await Promise.all([
    SanityClient.fetchSettings(),
    SanityClient.fetchLandingMenuSnippetPages(),
  ]);

  if (!settings || !landingMenuSnippetPages) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      settings,
      landingMenuSnippetPages,
      globals: {
        settings,
      },
    },
    revalidate: REVALIDATION_TIME,
  };
};

export default Custom404;
