import React from 'react';
import BaseButton from 'atoms/BaseButton';

const LogoButton = () => {
  return (
    <BaseButton
      label="Manhattan Hydraulics"
      url="/"
      direction="none"
      isInternal={true}
    />
  );
};

export default LogoButton;
