import React, { FC } from 'react';
import cx from 'classnames';

import CutoutCircles from 'atoms/CutoutCircles';
import LogoButton from 'atoms/LogoButton';
import BaseButton from 'atoms/BaseButton';

import GenericLink from 'atoms/Link';

import useWindowSize from 'hooks/useWindowSize';

import { ErrorPageSettings, LandingMenuSnippetPages } from 'lib/sanity/types';

export type ErrorPageProps = {
  settings: ErrorPageSettings | null;
  landingMenuSnippetPages: LandingMenuSnippetPages;
};

const ErrorPage: FC<ErrorPageProps> = ({
  settings,
  landingMenuSnippetPages,
}) => {
  const windowHeight = useWindowSize().height;
  const backgroundHexValue =
    settings && settings.backgroundColor ? settings.backgroundColor.hex : null;
  const backgroundColor = backgroundHexValue ? backgroundHexValue : '#ffffff';
  const signPlateAppearance = settings && settings.signPlateAppearance;

  return (
    <div
      className="w-screen h-screen bg-white flex flex-col"
      style={{
        height: windowHeight ? windowHeight + 'px' : '100vh',
        backgroundColor: backgroundColor,
      }}
    >
      <div className="h-tab font-book sticky top-0 flex flex-row-reverse laptop:flex-row justify-between w-full p-4 laptop:px-6 laptop:py-4 items-center z-nav font-lighter-normal">
        <div className="hidden laptop:flex flex-row space-x-6">
          {landingMenuSnippetPages?.map((page) => {
            return (
              <BaseButton
                label={page.title}
                url={`/${page.slug}`}
                direction="none"
                isInternal={true}
                key={page._id}
                hover={true}
              />
            );
          })}
        </div>
        <LogoButton />
      </div>
      <div
        className={cx(
          'rounded-2xl relative flex flex-col items-center justify-between mx-4 laptop:mx-6 h-full mb-4 laptop:mb-6',
          {
            'text-white bg-black': signPlateAppearance === 'dark',
            'text-black bg-white': signPlateAppearance === 'light',
          },
        )}
      >
        <CutoutCircles backgroundColor={backgroundColor} />
        <div className="Footer__item-wrapper uppercase font-serif font-light h-full text-[40px] laptop:text-[80px] flex flex-col items-center justify-center text-center gap-3 leading-none max-w-[90%] py-content-normal">
          <div>
            {settings?.firstCTALabel &&
              (settings?.firstCTADeactivation ? (
                <span className="errorCTA">{settings?.firstCTALabel}</span>
              ) : (
                <GenericLink
                  link={settings?.firstCTAurl ? settings?.firstCTAurl : '/'}
                  className=""
                >
                  {settings?.firstCTALabel}
                </GenericLink>
              ))}
          </div>
          <div>
            {settings?.secondCTALabel &&
              (settings?.secondCTADeactivation ? (
                <span className="errorCTA">{settings?.secondCTALabel}</span>
              ) : (
                <GenericLink
                  link={settings?.secondCTAurl ? settings?.secondCTAurl : '/'}
                  className=""
                >
                  {settings?.secondCTALabel}
                </GenericLink>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
