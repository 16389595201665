import React, { FC } from 'react';
import cx from 'classnames';

import Screw from 'svgs/Screw';

type ButtonProps = {
  label: string | null;
  direction?: string;
  className?: string;
  hover?: boolean;
  isScrewIncluded?: boolean;
};

const BaseButtonInner: FC<ButtonProps> = ({
  label,
  direction,
  className,
  hover,
  isScrewIncluded,
}) => {
  return (
    <div
      className={cx('BaseButtonInner flex items-center', className, {
        'flex-row justify-start': direction === 'right',
        'flex-row-reverse justify-end space-x-reverse': direction === 'left',
        'hover:bg-yellow': hover,
        'space-x-2': isScrewIncluded,
        'space-x-1': !isScrewIncluded,
      })}
    >
      {isScrewIncluded && <Screw className="w-4 inline align-middle" />}
      <div className="inline-block font-book">{label}</div>
      {direction && direction !== 'none' && (
        <div
          className={cx('inline-block transition-transform', {
            'BaseButton__arrow--right': direction === 'right',
            'BaseButton__arrow--left': direction === 'left',
          })}
        >
          {direction === 'left' ? '←' : '→'}
        </div>
      )}
    </div>
  );
};

export default BaseButtonInner;
